import type { Route } from 'vue-router';

export interface AuthenticatedContext {
  store: { commit: Function; getters: any; dispatch: Function };
  redirect: (string: string) => any;
  route: Route;
  next: any;
}

export default async function ({ store: { getters, commit, dispatch }, redirect, route, next }: AuthenticatedContext) {
  if (!process.browser) {
    return;
  }

  const isAuth: any = localStorage.getItem('auth');

  if (isAuth) {
    const parsedAuth = JSON.parse(isAuth);
    if (!parsedAuth.authJwt) {
      parsedAuth.authJwt = parsedAuth.userId;
    }
    commit('AuthenticationModule/setQuery', parsedAuth);

    if (route.path !== '/questionnaire' && route.path !== '/questionnaire/') {
      await dispatch('QuestionnaireModule/fetch');
    }

    const isQuestionnaireDone: any = getters['QuestionnaireModule/isPassed'];

    if (!isQuestionnaireDone && route.path !== '/questionnaire' && route.path !== '/questionnaire/') {
      next({
        path: '/questionnaire',
      });
    } else if (isQuestionnaireDone && (route.path === '/questionnaire' || route.path === '/questionnaire/')) {
      redirect('/');
    }
    return;
  }

  if (!getters['AuthenticationModule/isAuthenticated']) {
    redirect('/login');
  }
}
